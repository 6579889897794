import axios, { AxiosInstance } from "axios";
import Cookies from "js-cookie";
import { SESSION_KEY } from "ui/lib/constants";

const attachAuthInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = Cookies.get(SESSION_KEY);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

const localApiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const apiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor for adding auth token from cookie
attachAuthInterceptors(apiClient);
attachAuthInterceptors(localApiClient);

export { apiClient, localApiClient };
