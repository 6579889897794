type Entitlement = {
  granterType: string;
  granterID: string;
  id: string;
  userID: string;
  privilegeKey: string;
  effectiveStartTSStr: string | null;
  effectiveEndTSStr: string | null;
  willRenew: boolean;
};

const privilegeKeyToPropertyMap = {
  "com.ladder.teams.modify": "canModifyTeams",
  "com.ladder.workouts.save.tier1": "canSaveTier1Workouts",
  "com.ladder.workouts.save.tier2": "canSaveTier2Workouts",
  "com.ladder.workouts.save.tier3": "canSaveTier3Workouts",
  "com.ladder.workouts.save.custom": "canSaveCustomWorkouts",
  "com.ladder.chats.coach.access": "hasCoachChatAccess",
  "com.ladder.chats.team.private": "hasPrivateChatAccess",
  "com.ladder.workouts": "canWorkout",
  "com.apollo.workouts": "canCreateEditWorkouts",
  "com.apollo.teams": "canCreateEditTeams",
  "com.apollo.workouts.live": "canEditLiveWorkouts",
  "com.apollo.members.table": "canViewMemberTable",
  "com.apollo.users.entitlements.modify": "canEditUserEntitlements",
};

export function getUserPrivileges(userEntitlements: Entitlement[]) {
  const currentTime = new Date().toISOString();
  const userPrivileges = Object.fromEntries(
    Object.values(privilegeKeyToPropertyMap).map((property) => [
      property,
      false,
    ])
  );

  userEntitlements.forEach((privilege) => {
    const { privilegeKey, effectiveStartTSStr, effectiveEndTSStr } = privilege;

    const isActive =
      (effectiveStartTSStr === null || effectiveStartTSStr <= currentTime) &&
      (!effectiveEndTSStr || effectiveEndTSStr >= currentTime);

    if (isActive && privilegeKey in privilegeKeyToPropertyMap) {
      userPrivileges[privilegeKeyToPropertyMap[privilegeKey]] = true;
    }
  });

  return userPrivileges;
}
