export const ANNOUNCEMENT_ROOM_ID = "00000000-0000-4000-a000-000000000001";
export const TEAM_LADDER_USER_ID = "9d8b84a8-0389-4609-b10b-304a6a5f0e0c";
export const REST_MOVEMENT_ID = "6a38ccd8-e291-4883-b73d-836215a94785";
export const SESSION_KEY = "joinladder.session";
export const ARNOLD_USER_ID = "70c07181-1e91-49b6-af9d-28347c96c90b";

export const ARNOLD_USER = {
  id: "70c07181-1e91-49b6-af9d-28347c96c90b",
  familyName: null,
  givenName: "Arnold",
  profileImg: "https://ladderteams.imgix.net/profile-images/ArnoldMacIcon.png",
  height: 74,
  weight: 220.5,
  gender: "M",
  createdAt: "2020-05-07T18:44:18.352Z",
  lastActive: "2024-02-27T17:19:49.748Z",
  role: "admin",
  trialTokens: 2,
  roomId: "a016c3fa-b45b-4ff6-9e2b-caa6d922a178",
  streakCount: 0,
  membershipStatus: "free",
  trialExpiration: "2020-05-14T18:44:18.352Z",
  email: "arnold@joinladder.com",
  teamID: "50238840-4611-42a3-a5d0-88e9beaf9415",
  localTZ: "america/chicago",
  bootcampId: "50238840-4611-42a3-a5d0-88e9beaf9415",
  completionStats: {
    workoutId: "ce24027d-cb07-4f7b-8b5b-c5dcaf5dd6b3",
    completedAt: "2024-01-22T01:30:00+00:00",
    completionNumber: 9,
    rating: null,
  },
  oobPubnubChannel: "oob_user.70c07181-1e91-49b6-af9d-28347c96c90b",
};

export const keyCodes = {
  enter: 13,
  escape: 27,
  arrowDown: 40,
  arrowUp: 38,
  tab: 9,
};

export const teamProfileImages = {
  "ff97ac35-26fd-46b4-a183-2600dab319d8": "/assets/images/coaches/td.png", // dev id
  "c879f00b-6741-4cd1-8a10-802bfc514162": "/assets/images/coaches/td.png", // prod id
  "b94c2c5d-2339-4454-9d76-8b80f4b10d26": "/assets/images/coaches/max.png", // dev id
  "1b5eb33b-281d-4824-9a7a-b47a7fa3f538": "/assets/images/coaches/max.png", // prod id
};

export const CHAT_FLAGS = {
  // upcomingTrialExp: "COACH - Upcoming Trial Exp",
  coachGeneral: {
    label: "COACH - General",
    rank: 0,
  },
  needsAttention: {
    label: "AC - Needs Attention",
    rank: 0,
  },
  unsatisfactoryWorkout: {
    label: "AC - Low Workout Rating",
    rank: 3,
    deprecated: true,
  },
  memberInsufficientWorkouts2: {
    label: "AC - [IMP] No WO +12 Days",
    rank: 4,
    deprecated: true,
  },
  failedSubscriptionRenewal: {
    label: "AC - Failed Renewal Payment",
    rank: 5,
    deprecated: true,
  },
  expiringEngagedTrialer: {
    label: "AC - [IMP] Expiring Tomorrow w/ WO",
    rank: 9,
    deprecated: true,
  },
  memberInsufficientWorkoutsMidweek: {
    label: "AC - [IMP] Wednesday No WO",
    rank: 10,
    deprecated: true,
  },
  unacknowledgedWorkoutMilestone: {
    label: "AC - [IMP] WO Milestone Shoutout",
    rank: 12,
    deprecated: true,
  },
  expiredEngagedTrialer: {
    label: "AC - [IMP] Expired Trial w/ WO",
    rank: 2,
    deprecated: true,
  },
  coachMessageStarved: {
    label: "AC - [IMP] No Message +10 Days",
    rank: 7,
    deprecated: true,
  },
  coachMessageStarvedActive: {
    deprecated: true,
    label: "AC - [IMP] No Msg +10 Days (Healthy/Active)",
    rank: 7,
  },
  coachMessageStarvedInactive: {
    label: "AC - [IMP] No Msg +10 Days (Slipping/Pulse)",
    rank: 8,
    deprecated: true,
  },
  memberLackingWelcome: {
    label: "AC - [IMP] Paid Member Welcome",
    rank: 6,
    deprecated: true,
  },
  unacknowledgedBirthday: {
    label: "COACH - HBD Shoutout",
    rank: 11,
    deprecated: true,
  },
  healthSlipping: {
    label: "AC - [IMP] Slipping",
    rank: 11,
    deprecated: true,
  },
  engagedTrialer: {
    deprecated: true,
    label: "AC - [IMP] Trial w/ WO Day 3 Check In",
    rank: 4,
  },
  engagedTrialer2: {
    label: "AC - [IMP] Trialing First WO Completed",
    rank: 5,
    deprecated: true,
  },
  expiringTrialerInsufficientWorkouts: {
    label: "AC - [IMP] Expiring Trial No WO",
    rank: 10,
    deprecated: true,
  },
  trialerInsufficientWorkouts: {
    deprecated: true,
    label: "AC - [IMP] Trialing NO WO 48 Hours",
    rank: 9,
  },
  healthPulse: {
    label: "AC - [IMP] Pulse",
    rank: 11,
    deprecated: true,
  },
  healthDead: {
    label: "AC - [IMP] Dead",
    rank: 11,
    deprecated: true,
  },
  expiredTrialerActiveRecent: {
    label: "AC - [IMP] Trial Expired ≤ 7 Days, Active",
    rank: 12,
    deprecated: true,
  },
  expiredTrialerActiveOld: {
    label: "AC - [IMP] Trial Expired > 7 Days, Active",
    rank: 12,
    deprecated: true,
  },
  trialerLackingIntroduction: {
    label: "AC - [IMP] Trial Intro Message",
    rank: 1,
    deprecated: true,
  },
  memberInsufficientWorkouts: {
    label: "AC - [IMP] No WO +7 Days",
    rank: 4,
    deprecated: true,
  },
};

export const CHAT_FLAGS_KEYS = Object.keys(CHAT_FLAGS);

export const FEATURE_FLAGS = {
  AUTH: process.env.NEXT_PUBLIC_FEATURE_FLAG_AUTH === "true",
  WORKOUT_CREDIT:
    process.env.NEXT_PUBLIC_FEATURE_FLAG_WORKOUT_CREDIT === "true",
};

export const WO_EMAIL_CAPTURE_COOKIE_NAME = "WO_EMAIL_CAPTURE";
export const HOME_EXPERIMENT_ID = "JjgKz79KQaapKlnQ_piuYQ";
export const WORKOUT_EXPERIMENT_ID = "ixJsAky2Rbe7tCPeqMp6zg";

export const AUTH_REDIRECT_SEARCH_PARAMS_COOKIE_KEY =
  "auth-redirect-search-params";
export const AUTH_REDIRECT_COOKIE_KEY = "auth-redirect-pathname";
export const SUBSCRIPTION_DEEPLINK = "SUBSCRIPTION_DEEPLINK";

export const BASE_QUIZ_VARIANT =
  process.env.NEXT_PUBLIC_BASE_QUIZ_VARIANT || "riddler_hybrid_og_v2";

export const events = {
  Web_Quiz_Started: "Match Quiz Started",
  Pre_Quiz_CTA_Clicked: "Match Quiz CTA Clicked",
  Web_Quiz_Completed: "Match Quiz Completed",
  Survey_Sign_In_With_Apple_Clicked: "Match Quiz Sign In With Apple Clicked",
  Web_Authenticated: "Match Quiz User Authenticated",
  APPSTORE_REDIRECT: "Match Quiz App Store Redirect Activated",
  Goal_Question_Answered: "Match Quiz Goal Question Answered",
  Benefit_Question_Answered: "Match Quiz Benefit Question Answered",
  Age_Question_Answered: "Match Quiz Age Question Answered",
  WO_Days_Question_Answered: "Match Quiz WO Days Question Answered",
  WO_Length_Question_Answered: "Match Quiz WO Length Question Answered",
  Equipment_Question_Answered: "Match Quiz Equipment Question Answered",
  Preferred_Styles_Question_Answered:
    "Match Quiz Preferred Styles Question Answered",
  Coach_Gender_Pref_Question_Answered:
    "Match Quiz Coach Gender Pref Question Answered",
  Web_Email_Captured: "Web Email Captured",
};

export const DEMO_FALLBACK_PORTRAITS = [
  "https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1542909168-82c3e7fdca5c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1519699047748-de8e457a634e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1597223557154-721c1cecc4b0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1447194047554-cfe888edc98c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1518991669955-9c7e78ec80ca?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1518991669955-9c7e78ec80ca?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1518577915332-c2a19f149a75?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1485893086445-ed75865251e0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1504553101389-41a8f048c3ba?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
  "https://images.unsplash.com/photo-1520451644838-906a72aa7c86?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&q=80",
];

export const daysOfWeekLong = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
