import GraphemeSplitter from "grapheme-splitter";
const graphemeSplitter = new GraphemeSplitter();
export function buildFullName(
  givenName?: string,
  familyName?: string
): string | undefined {
  let fullName;
  if (givenName) {
    fullName = givenName;
  }
  if (familyName) {
    if (fullName) {
      fullName += " " + familyName;
    } else {
      fullName = familyName;
    }
  }
  return fullName;
}
export function buildShortName({
  givenName,
  familyName,
}: {
  givenName?: string | null;
  familyName?: string | null;
}) {
  let shortName = givenName || "";

  if (familyName) {
    const graphemes = graphemeSplitter.splitGraphemes(familyName);
    if (graphemes.length > 0) {
      if (shortName.length > 0) {
        shortName += " ";
      }
      shortName += graphemes[0];
    }
  }
  return shortName;
}
export function buildCoachName(givenName: string) {
  return `Coach ${givenName}`;
}
