declare global {
  interface Window {
    dataLayer: any[];
  }
}

export function gaHandler(...args: any[]) {
  const dataLayer = (window.dataLayer = window.dataLayer || []);
  dataLayer.push(...args);
}

interface PageviewParams {
  url: string;
}

export const pageview = ({ url }: PageviewParams): void => {
  gaHandler("config", process.env.NEXT_PUBLIC_GA_TRACKING_ID, {
    page_path: url,
  });
};

interface EventParams {
  action: string;
  category?: string;
  label?: string;
  value?: string | number;
}

export const event = ({
  action,
  category,
  label,
  value,
}: EventParams): void => {
  gaHandler("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

interface ConfigParams {
  [key: string]: any; // Replace 'any' with a more specific type if possible
}

export const config = (data: ConfigParams = {}): void => {
  gaHandler("config", process.env.NEXT_PUBLIC_GA_TRACKING_ID, data);
};

interface UserPropertyParams {
  [key: string]: any; // Replace 'any' with a more specific type if possible
}

export const setUserProperty = (data: UserPropertyParams = {}): void => {
  gaHandler("set", "user_properties", data);
};

interface SetExperimentParams {
  experimentId: string;
  variantId: string;
}

export const setExperiment = ({
  experimentId,
  variantId,
}: SetExperimentParams): void => {
  gaHandler("set", "exp", `${experimentId}.${variantId}`);
};
