import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

import { Theme } from "ui/themes";

type Config = {
  theme: Theme["name"];
  radius: number;
};

export const config = {
  theme: "ladder" as Theme["name"],
  radius: 0.5,
};

const configAtom = atomWithStorage<Config>("ladder-web:config", config);

export function useConfig() {
  return useAtom(configAtom);
}
