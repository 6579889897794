import * as Sentry from "@sentry/nextjs";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { apiClient as fetcher } from "queries/constants";
import { useCallback } from "react";
import useSWR, { SWRResponse, mutate } from "swr";
import { useSession } from "ui/containers/session";
import {
  createDraggableMovementData,
  createSectionState,
} from "ui/lib/helpers";

interface FetcherConfig {
  useFetcher?: boolean;
  initialData?: any;
  newSession?: string;
  adaptData?: (response: any) => any;
  revalidateOnMount?: boolean;
  _fetcher?: AxiosInstance;
  [key: string]: any;
}

export { fetcher };

export async function prefetch(endpoint: string): Promise<any> {
  try {
    const res = await (
      await fetch(process.env.NEXT_PUBLIC_API_BASE_URL + endpoint)
    ).json();
    mutate(endpoint, res, false);
    return res;
  } catch (error) {
    console.error({ error });
    Sentry.captureException(error);
  }
}

export async function handleUpdateRequest(
  url: string,
  data: any,
  method: "patch" | "post" | "put" = "patch"
): Promise<AxiosResponse<any> | null> {
  if (!url) return Promise.resolve(null);
  fetcher.defaults.baseURL = process.env.NEXT_PUBLIC_API_BASE_URL;
  return await fetcher[method](url, data);
}

const customMapper = (request: string, data: any): any => {
  const paths = request.split("/");

  if (paths[0] === "workouts") {
    return {
      sectionState: createSectionState(data),
      ...data,
    };
  }
  if (paths[paths.length - 1] === "movements") {
    return createDraggableMovementData(data);
  }

  return data;
};

const useRequest = (
  request: string = "",
  {
    useFetcher = true,
    initialData,
    newSession,
    adaptData,
    revalidateOnMount,
    // @ts-ignore
    _fetcher = fetcher,
    ...config
  }: FetcherConfig = {}
): SWRResponse<any, any> & { revalidate: () => void } => {
  const { session } = useSession();
  const hasSession = !!(session || newSession);

  const sessionToken = newSession || session;
  const authHeader: AxiosRequestConfig = sessionToken
    ? { headers: { Authorization: `Bearer ${sessionToken}` } }
    : {};

  const handleFetchResponse = useCallback(
    (request: string, response: AxiosResponse<any>) => {
      if (typeof adaptData === "function") return adaptData(response);
      return customMapper(request, response.data);
    },
    []
  );

  const fetcherToUse = () =>
    _fetcher(request, authHeader).then((response) =>
      handleFetchResponse(request, response)
    );

  const swrFetcher = useFetcher ? fetcherToUse : null;

  const swrConfig = {
    revalidateOnMount,
    dedupingInterval: 5000,
    revalidateOnFocus: false,
    focusThrottleInterval: 30000,
    ...config,
  };

  // Use type assertion if you are sure initialData is valid in your SWR version
  if (initialData !== undefined) {
    (swrConfig as any).initialData = initialData;
  }

  const swr = useSWR(
    hasSession && request ? request : null,
    swrFetcher,
    swrConfig
  );

  return {
    ...swr,
    revalidate: swr.mutate,
  };
};

export default useRequest;
