import axios from "axios";
import { getSession } from "ui/containers/session";

export const rockyFetcher = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL_ROCKY,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getApiClient = () => {
  if (typeof window !== "undefined") {
    const session = getSession();
    rockyFetcher.defaults.headers["Authorization"] = `Bearer ${session}`;

    return rockyFetcher;
  }
};

export const handleUpdateRequest = () => {
  if (typeof window !== "undefined") {
    const apiClient = getApiClient();

    const request = async (url, data, method = "post") => {
      return await apiClient[method](url, data);
    };

    return { request };
  }

  return {};
};
