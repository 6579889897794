import { useQuery } from "react-query";
import { getPaymentState } from "./services";
import { Entitlements } from "./types";

export const usePaymentState = (userID: string, props = {}) => {
  return useQuery<Entitlements | null>(
    ["getPaymentState", userID],
    () => getPaymentState(userID),
    {
      enabled: !!userID, // only run the query if a term is provided
      ...props,
    }
  );
};
