import { DrukBold, DrukWideBold, SFProDisplayBold, SFProDisplayHeavy, SFProDisplayMedium, SFProDisplayRegular } from "./fonts";
export const FontProvider = ({
  children
}) => {
  return <>
      <style jsx global>{`
        html {
          --sfpro-display-regular: ${SFProDisplayRegular.style.fontFamily};
          --sfpro-display-medium: ${SFProDisplayMedium.style.fontFamily};
          --sfpro-display-bold: ${SFProDisplayBold.style.fontFamily};
          --sfpro-display-heavy: ${SFProDisplayHeavy.style.fontFamily};
          --druk-bold: ${DrukBold.style.fontFamily};
          --druk-wide-bold: ${DrukWideBold.style.fontFamily};
        }
      `}</style>
      {children}
    </>;
};