import * as Sentry from "@sentry/nextjs";
import {
  addDays,
  addWeeks,
  format,
  fromUnixTime,
  isAfter,
  isBefore,
  isThisWeek,
  isWithinInterval,
  startOfDay,
  startOfISOWeek,
} from "date-fns";
import flatten from "lodash/flatten";
import orderBy from "lodash/orderBy";
import * as R from "ramda";
import { buildShortName } from "ui/components/chat/name-helper";
import { v4 } from "uuid";
import { REST_MOVEMENT_ID } from "./constants";

export const fn = () => null;

export const isNumber = (val) => !isNaN(Number(val)) && !(val instanceof Date);

export const wait = (duration) =>
  new Promise((resolve) => setTimeout(resolve, duration));

function dateHasTimeZone(date) {
  try {
    return date.endsWith("Z") || date.includes("+");
  } catch (e) {
    console.error(e, date);
  }
}

export function extractDelimitedGroupsFromString(string) {
  // Matches a string that has the following structure:
  // group1 • group2 | group3
  // Where group1, group2, and group3 are sequences of characters separated by
  // zero or more whitespace characters and group2 and group3 are optional.

  const regex = /^(.+?)\s*(•\s*(.*?)\s*)?(\|\s*(.*?)\s*)$/;
  const match = regex.exec(string);
  if (match) {
    const [, group1, , group2, , group3] = match;
    return [group1, group2, group3];
  }
  return string.split(/\s*•\s*/);
}

export function timeToUtc(date) {
  try {
    return new Date(date + (dateHasTimeZone(date) ? "" : "Z"));
  } catch (e) {
    console.error(e, date);
  }
}

export function getValidDate(date) {
  if (!date) return;
  if (isNumber(date)) return fromUnixTime(Number(date));
  return typeof date === "string" ? timeToUtc(date) : date;
}

export const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

// given a date from the server, return yyyy-MM-dd
export const getDateKey = (date) => {
  let dateToUse = date;
  if (typeof date === "string") {
    dateToUse = new Date(date.replace(/-/g, "/").replace(/T.+/, ""));
  }
  return format(dateToUse, "yyyy-MM-dd");
};

// server doesn't like 0s
export const getOrder = (order) => {
  return order === 0 ? -1 : order;
};

export function getTimeDataFromSeconds(seconds) {
  // Hours, minutes and seconds
  const init = {
    hrs: 0,
    mins: 0,
    secs: 0,
  };
  if (!seconds) return init;
  init.hrs = ~~(seconds / 3600);
  init.mins = ~~((seconds % 3600) / 60);
  init.secs = ~~seconds % 60;

  return init;
}
export function fancyTimeFormat(duration) {
  // Hours, minutes and seconds
  if (!duration) return "0:00";
  const { hrs, mins, secs } = getTimeDataFromSeconds(duration);

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

export const sleep = (time) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const createSectionsDict = (data) => {
  const dict = {};
  data?.map?.((s) => {
    dict[s.id] = {
      ...s,
      id: s.id,
      name: s.name,
      itemIds: orderBy(
        s?.movements?.map?.((m) => m.id),
        ["order"]
      ),
    };
  });
  return dict;
};

export const createItemsDict = (data) => {
  const dict = {};
  data?.map?.((s) => {
    if (!s) return;
    dict[s.id] = { ...s, clientId: v4() };
  });
  return dict;
};

export const createSectionState = (data) => {
  return {
    items: createItemsDict(flatten(data?.sections?.map?.((s) => s.movements))),
    groups: createSectionsDict(data?.sections),
    groupOrder: orderBy(
      data?.sections?.map?.((s) => s.id),
      ["order"]
    ),
  };
};

export const createDraggableMovementData = (data) => {
  let restIdKey;
  const mediaRandomId = v4();
  const restRandomId = v4();
  const customMediaMovement = {
    name: "Custom video",
    type: "media",
    duration: 0,
    clientId: mediaRandomId,
    source: "client",
  };

  const restMovement = {
    type: "movement",
    category: "rest",
    name: "Rest",
    duration: 30,
    movementId: REST_MOVEMENT_ID,
    clientId: restRandomId,
    source: "client",
    thumbnailUrl:
      "https://ladderteams.imgix.net/movement-thumbnails/rest-LadderStudioRender.jpg",
  };

  const draggableMovementData = {
    [mediaRandomId]: customMediaMovement,
    [restRandomId]: restMovement,
  };
  const formattedData = data.map((n) => {
    const clientId = v4();
    const newObj = { ...n, clientId };
    draggableMovementData[clientId] = newObj;
    if (n.id === REST_MOVEMENT_ID) {
      restIdKey = clientId;
    }
    return newObj;
  });

  return {
    draggableMovementData,
    formattedData: [customMediaMovement, restMovement, ...formattedData],
    restIdKey,
  };
};

export const alphabetalSort = (data, key) => {
  if (!data) return data;
  if (!key) return data;

  return data.sort(function (a, b) {
    var nameA = a[key].toUpperCase(); // ignore upper and lowercase
    var nameB = b[key].toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1; //nameA comes first
    }
    if (nameA > nameB) {
      return 1; // nameB comes first
    }
    return 0; // names must be equal
  });
};

export const getId = ({ optimistic, id }) => (optimistic ? v4() : id);

export const getMovementType = (initialType, hasInstructionalAudio) => {
  if (hasInstructionalAudio || "media" === initialType) return "media";
  return "movement";
};

export const getWeekStatus = (date) => {
  if (!date) return {};
  const dateToUse =
    typeof date === "object" ? date : addDays(getValidDate(date), 1);
  const weekStartsOn = 1;
  const currentstartOfWeek = startOfISOWeek(new Date());
  const currentWorkoutStartOfWeek = startOfISOWeek(dateToUse);
  const data = {
    isNextWeek:
      isAfter(currentWorkoutStartOfWeek, currentstartOfWeek) &&
      isBefore(currentWorkoutStartOfWeek, addWeeks(currentstartOfWeek, 2)),
    isArchivedWeek: isBefore(currentWorkoutStartOfWeek, currentstartOfWeek),
    isCurrentWeek: isThisWeek(dateToUse, { weekStartsOn }),
  };
  return data;
};

// list of user ids (coaches) who can edit current week
const adminAccessUsers = [];

export const canUserEdit = ({ id, role, date }) => {
  const { isCurrentWeek, isArchivedWeek } = getWeekStatus(date);
  if (isArchivedWeek) return false;

  // todo: remove this line when we don't want coaches to edit current weeks
  if (isCurrentWeek) return true;

  if (isCurrentWeek && adminAccessUsers.includes(id)) return true;
  if (isCurrentWeek && role !== "admin") return false;

  return true;
};

export const videoIdEncoding = (videoUrl) => {
  if (!videoUrl) return false;

  const searchParams = new URLSearchParams(new URL(videoUrl).search);

  if (Boolean(searchParams.get("processing"))) {
    return true;
  }

  if (!videoUrl.includes("apollo-upload")) return false;

  return true;
};

export const getCalendarBackgroundColor = ({
  isCurrentWeek,
  isArchivedWeek,
  isNextWeek,
}) => {
  if (isNextWeek) {
    return "#FFF7F6";
  }
  if (isCurrentWeek) {
    return "#F9F9FF";
  }
  if (isArchivedWeek) {
    return "#F8F8F8";
  }
  return "#fff";
};

export function getMediaAspectRatio({ width, height }) {
  const ratio = height / width;
  if (ratio > 1.25) {
    return "portrait";
  } else if (ratio < 0.75) {
    return "landscape";
  } else {
    return "square";
  }
}

export function toFeet(inches) {
  if (!inches) return;
  let feet = Math.floor(inches / 12);
  let remainingInches = inches - feet * 12;
  return `${feet}'${remainingInches}"`;
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function stringContains(target, pattern) {
  var value = 0;
  pattern.forEach(function (word) {
    value = value + target.includes(word);
  });
  return value === 1;
}

export const validateHref = (href) => {
  if (href.includes("mailto:", 0)) return href;
  if (validateEmail(href)) return `mailto:${href}`;
  if (stringContains(href, ["ladder://"])) return href;
  if (stringContains(href, ["https://", "http://"])) return href;
  return `https://${href}`;
};

export function validURL(str) {
  var res = str.match(
    /(http(s)?|ladder):\/\/([-\w]+\.)*[-\w]+(:\d+)?(\/([-\w@:%_+.~#?,&//=]*))?/g
  );
  return res !== null;
}

export function removeQuotes(str) {
  if (!str) return str;
  return str.replace(/['"]+/g, "");
}

export async function downloadFile(url, videoFileName = "") {
  const response = await fetch(url);
  const file = await response.blob();
  let tempUrl = URL.createObjectURL(file);
  const aTag = document.createElement("a");
  aTag.href = tempUrl;
  aTag.download = videoFileName || url.replace(/^.*[\\/]/, "");
  document.body.appendChild(aTag);
  aTag.click();
  URL.revokeObjectURL(tempUrl);
  aTag.remove();
}

export function constructMuxStaticURL(videoAsset) {
  const muxAssetData = videoAsset.muxAssetData;
  const muxAsset = muxAssetData.muxAsset;
  const staticRenditions = muxAsset.static_renditions;

  if (
    staticRenditions.status === "ready" &&
    staticRenditions.files.length > 0
  ) {
    const playbackId = muxAsset.playback_ids[0].id;
    const files = staticRenditions.files;

    // Sort the files array based on the quality order
    const qualityOrder = ["low", "medium", "high"];
    files.sort((a, b) => {
      const qualityA = qualityOrder.indexOf(a.name.split(".")[0]);
      const qualityB = qualityOrder.indexOf(b.name.split(".")[0]);
      return qualityB - qualityA;
    });

    // Get the highest quality file
    const highestQualityFile = files[0];
    const muxURL = `https://stream.mux.com/${playbackId}/${highestQualityFile.name}`;
    return muxURL;
  }
}

export const createMUXPosterURL = (playbackId, time = 0) =>
  playbackId
    ? `https://image.mux.com/${playbackId}/thumbnail.jpg?time=${time}`
    : "";

export const createMUXHlsAudioUrl = (playbackId) =>
  playbackId ? `https://stream.mux.com/${playbackId}.m3u8` : "";

export const createMUXMP4AudioUrl = (playbackId) =>
  playbackId ? `https://stream.mux.com/${playbackId}/audio.m4a` : "";

export function isFlagMuted({ muteStartAt, muteEndAt }) {
  try {
    if (!muteStartAt && !muteEndAt) return false;

    return isWithinInterval(new Date(), {
      start: getValidDate(muteStartAt),
      end: startOfDay(getValidDate(muteEndAt)),
    });
  } catch (error) {
    console.error(error, { muteStartAt, muteEndAt });
    Sentry.captureException(error);
    return false;
  }
}

export function findUniversalFlagSettings(flagSettings) {
  if (!flagSettings || !flagSettings.length) return false;
  return flagSettings.find(({ flagType }) => flagType === "all");
}

export function roomFlagsAreMuted(flagSettings) {
  const universalFlagSettings = findUniversalFlagSettings(flagSettings);
  if (!universalFlagSettings) return false;
  return isFlagMuted(universalFlagSettings);
}

export function hasActiveEntitlement(key, entitlements) {
  if (!entitlements?.length) return false;
  const found = entitlements.find(({ privilegeKey }) => privilegeKey === key);
  if (!found) return false;

  return isAfter(new Date(), getValidDate(found.effectiveEndTS));
}

export function getUploadingMessage(percent, processing = false) {
  if (processing) return "Processing";
  if (!percent || percent < 10) return "Getting started";
  if (percent < 50) return "About a minute left";
  if (percent < 75) return "Less than a minute left";
  if (percent < 85) return "About 30 seconds left";
  if (percent < 95) return "Wrapping up";
  return "Ready";
}

export const pluralize = (count, word, suffix = "s") => {
  if (count === 1) return word;
  return word + suffix;
};

export function asyncMap(arr, mapper) {
  var q = Promise.resolve();
  return Promise.all(arr.map((v) => (q = q.then(() => mapper(v)))));
}

export const isDev = !["production", "prod"].includes(
  process.env.NEXT_PUBLIC_ENVIRONMENT
);

export const accessState = async (setState) => {
  return new Promise((resolve) => {
    setState((currentState) => {
      resolve(currentState);
      return currentState;
    });
  });
};

export const getMemberShortName = (user) => {
  if (!user) return "";
  const { role, givenName, familyName } = user;

  if (role === "coach") return `Coach ${user.givenName}`;
  return `${buildShortName({ givenName, familyName })}`;
};

export const isTrue = (n) => !!n;
export const isFalse = (n) => !n;
export const includes = (n = []) => n.includes;
export const excludes = (n = []) => !n.includes;
export const filter = (n = []) => n.filter;

export const conditional = (condition, defaultValue) =>
  R.ifElse(condition, R.identity, R.always(defaultValue));

export function createDictionary(data, key = "id") {
  if (!data?.length) return {};

  const dictionary = {};
  data.map((n) => {
    dictionary[n[key]] = n;
  });

  return dictionary;
}

export function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator?.platform) ||
    // iPad on iOS 13 detection
    (navigator?.userAgent?.includes?.("Mac") && "ontouchend" in document)
  );
}

export function isMobile() {
  return /Android|webOS|iPhone/i.test(navigator?.userAgent);
}

export const focusChatInput = ({
  focusOnMobile = false,
  shouldSelect = false,
  activePubnubChannel = "",
} = {}) => {
  setTimeout(() => {
    if (!isMobile() || focusOnMobile) {
      const input = document.querySelector(
        `[data-type="chat-input-${activePubnubChannel}"]`
      );
      input?.focus?.();
      if (shouldSelect) {
        input?.select?.();
      }
    }
  }, 100);
};

// limit: filters an array to a specified number of elements,
// starting from the beginning
export const limit = (array, limitNumber) => {
  if (!array || !array?.length) return array;
  if (typeof limitNumber !== "number") return array;

  return array.filter((_, i) => {
    if (i <= limitNumber - 1) return true;
  });
};

// limitLast: filters an array to a specified number of elements,
// starting from the end
export const limitLast = (arr, maxLength) => {
  if (arr.length > maxLength) {
    arr.splice(0, arr.length - maxLength);
  }
  return arr;
};

export const normalizeSubType = (subType) => {
  const types = ["PRO", "ELITE", "Legacy"];

  for (let type of types) {
    if (subType.startsWith(type)) {
      return type;
    }
  }

  return null;
};

export const getOptionsFromRooms = (rooms) => {
  if (!rooms) return [];

  let countMap = new Map();

  rooms.forEach((obj) => {
    let subType = obj.user?.activeSubType;

    if (subType) {
      let normalizedSubType = normalizeSubType(subType);
      if (normalizedSubType) {
        let count = countMap.get(normalizedSubType) || 0;
        countMap.set(normalizedSubType, count + 1);
      }
    }
  });

  return Array.from(countMap.entries());
};
