import { AxiosResponse } from "axios";
import { apiClient } from "../constants";
import { Entitlements } from "./types";

export const getPaymentState = async (
  userID: string
): Promise<Entitlements | null> => {
  if (!userID) return null;

  const response: AxiosResponse<Entitlements> = await apiClient.get(
    `v1/getPaymentState?userID=${userID}`
  );

  return response.data;
};
