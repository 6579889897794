import React from "react";
import { createContainer } from "unstated-next";

const useContainer = () => {
  const [isHeroCTAInView, setIsHeroCTAInView] = React.useState(true);

  return {
    isHeroCTAInView,
    setIsHeroCTAInView,
  };
};

const { useContainer: useApp, Provider: AppProvider } =
  createContainer(useContainer);

export { useApp, AppProvider };
