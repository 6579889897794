import { apiClient } from "queries/constants";
import { isDev } from "ui/lib/helpers";
import { Team, TeamResponse, TeamsResponse } from "./types";

export const listTeams = async (): Promise<Team[]> => {
  const { data } = await apiClient.get<{ teams: Team[] }>(
    `/v1/listTeams${isDev ? "?authUserID=arnold" : ""}`
  );

  return data.teams;
};
export const getTeamsData = async (): Promise<TeamsResponse> => {
  const { data } = await apiClient.get<TeamsResponse>(
    `/v1/listTeams${isDev ? "?authUserID=arnold" : ""}`
  );

  return data;
};

export const getTeam =
  (teamID = "") =>
  async () => {
    const response = await apiClient.get<TeamResponse>(
      `/v1/getTeam?teamID=` + teamID
    );

    return response.data;
  };

export const setMemberTeam = async (
  bootcampId: string,
  userId: string,
  reason?: string
) => {
  const response = await apiClient.post<{
    bootcampWasChanged: boolean;
    isFirstBootcamp: boolean;
    membershipStatus: string;
    createdIntroTrialPeriodID: string | null;
    createdBootcampUserHistoryID: string | null;
  }>(`/bootcamps/setUserBootcamp`, {
    bootcampId,
    userId,
    reason,
  });

  return response.data;
};
