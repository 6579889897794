export const identify = ({ ...data }) => {
  console.log("identify", { ...data });
  if (typeof window?.analytics?.track === "undefined") return;

  if (data.userId) {
    return window.analytics.identify(data.userId, {
      ...data,
    });
  }

  window.analytics.identify({
    ...data,
  });
};
