import { AxiosResponse } from "axios";
import { apiClient } from "../constants";
import { Account } from "./types";

const getPath = (userID: string) => `/v1/getUserAccount?userID=${userID}`;

export const getAccount = async (userID: string): Promise<Account> => {
  if (!userID) {
    throw new Error("User ID is required");
  }

  const response: AxiosResponse<Account> = await apiClient.get(getPath(userID));

  return response.data;
};
