import { differenceInSeconds } from "date-fns";
import { events } from "./constants";
import { event } from "./gtag";
import { isDev } from "./helpers";

interface Analytics {
  track: (action: string, data: any) => void;
  page: () => void;
}

declare global {
  interface Window {
    analytics?: Analytics;
  }
}

interface CapturedEvent {
  [key: string]: {
    value?: string;
    timestamp: Date;
  };
}

const capturedEvents: CapturedEvent = {};
const staticValues: {
  fbp: string | null;
  fbc: string | null;
} = {
  fbp: null,
  fbc: null,
};

interface EventData {
  [key: string]: any; // Replace 'any' with more specific types as needed
}

const updateCapturedEvents = (action: string, data: EventData = {}): void => {
  if (!action) return;
  capturedEvents[action] = {
    ...data,
    timestamp: new Date(),
  };
};

const eventIsDuplicated = (action: string, data: EventData): boolean => {
  if (action === "Match_Quiz_CTA_Clicked") return false;

  if (!data?.value) return false;
  if (typeof data?.value !== "string") return false;
  if (!capturedEvents[action]?.value) return false;
  if (capturedEvents[action].value !== data.value) return false;
  if (!capturedEvents[action].timestamp) return false;

  return differenceInSeconds(new Date(), capturedEvents[action].timestamp) < 10;
};

export interface CaptureEventParams {
  action: string;
  skipMapping?: boolean;
  [key: string]: any; // Replace 'any' with more specific types as needed
}

export const captureEvent = ({
  action,
  skipMapping,
  ...data
}: CaptureEventParams): void => {
  if (eventIsDuplicated(action, data)) return;

  updateCapturedEvents(action, data);

  const fbc = staticValues.fbc || getFBC();
  const fbp = staticValues.fbp || getFBPCookie();

  if (isDev) {
    console.log("captureEvent", { action, fbc, fbp, ...data });
  }

  event({ action, ...data });

  if (typeof window?.analytics?.track === "undefined") return;

  window.analytics.track(!skipMapping ? events[action] || action : action, {
    ...data,
    fbc,
    fbp,
  });
};

function getFBPCookie() {
  var name = "_fbp=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      const fbp = c.substring(name.length, c.length);
      staticValues.fbp = fbp;
      return fbp;
    }
  }
  return "";
}

function getFBC() {
  // Function to get the value of a specific cookie by name
  function getCookieValue(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  // Function to get the value of a query parameter by name
  function getQueryParam(param) {
    var urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  }

  // Check if `fbclid` is present in the URL
  var fbclid = getQueryParam("fbclid");
  if (fbclid) {
    // If `fbclid` is present, store it in a cookie
    var fbc = "fb.1." + Date.now() + "." + fbclid;
    document.cookie = `fbc=${fbc}; path=/; domain=${window.location.hostname}`;
    staticValues.fbc = fbc;
    return fbc;
  } else {
    // If `fbclid` is not present, try to get `fbc` from the cookie
    return getCookieValue("fbc");
  }
}
