"use client";

import { cva, type VariantProps } from "class-variance-authority";
import { HTMLMotionProps, motion } from "motion/react";
import * as React from "react";
import { cn } from "ui/lib/utils";
const buttonVariants = cva("inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50", {
  variants: {
    variant: {
      default: "bg-primary text-primary-foreground shadow hover:bg-primary/90",
      destructive: "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
      "destructive-outline": "bg-destructive/10 hover:bg-destructive/20 border border-destructive text-destructive",
      outline: "border bg-transparent shadow-sm hover:bg-accent hover:text-accent-foreground",
      secondary: "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
      subtle: "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
      ghost: "hover:bg-accent hover:text-accent-foreground",
      link: "text-primary underline-offset-4 hover:underline",
      text: "text-primary"
    },
    size: {
      default: "h-9 px-4 py-2",
      sm: "h-8 px-3 text-xs",
      lg: "h-10 px-8",
      xl: "text-lg px-10 py-4",
      icon: "h-9 w-9"
    },
    shape: {
      default: "rounded",
      rounded: "rounded-full"
    }
  },
  defaultVariants: {
    variant: "default",
    size: "default",
    shape: "rounded"
  }
});
export interface ButtonProps extends Omit<HTMLMotionProps<"button">, keyof VariantProps<typeof buttonVariants>>, VariantProps<typeof buttonVariants> {
  className?: string;
}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  variant,
  size,
  shape,
  ...props
}, ref) => {
  return <motion.button className={cn(buttonVariants({
    variant,
    size,
    shape,
    className
  }))} ref={ref} {...props} />;
});
Button.displayName = "Button";
export { Button, buttonVariants };