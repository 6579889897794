"use client";

import { ThemeProvider as NextThemesProvider, useTheme } from "next-themes";
import { type ThemeProviderProps } from "next-themes/dist/types";
export function ThemeModeProvider({
  children,
  ...props
}: ThemeProviderProps) {
  return <NextThemesProvider {...props} data-sentry-element="NextThemesProvider" data-sentry-component="ThemeModeProvider" data-sentry-source-file="theme-mode-provider.tsx">{children}</NextThemesProvider>;
}
export const useThemeMode = useTheme;