import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";

export const SESSION_KEY = "joinladder.session";

export const getSession = () => Cookies.get(SESSION_KEY);

export const useSession = () => {
  const [sessionError, setSessionError] = useState(false);
  const [hasAccessedSession, setHasAccessedSession] = useState(false);

  const session = getSession();

  const setSession = useCallback((nextSession) => {
    if (nextSession) {
      Cookies.set(SESSION_KEY, nextSession, { expires: 365 });
    } else {
      Cookies.remove(SESSION_KEY);
    }
  }, []);

  useEffect(() => {
    // Check if we're in a browser environment
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionToken = urlParams.get("session-token");

      if (sessionToken) {
        setSession(sessionToken);
        urlParams.delete("session-token");
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.history.replaceState({}, "", newUrl);
      }

      setHasAccessedSession(true);
    }
  }, [setSession]);

  return {
    session,
    setSession,
    sessionError,
    setSessionError,
    hasAccessedSession,
  };
};
