import { useEffect, useState } from "react";

export const useEventListener = (event = "", action, props = [], bubble) => {
  useEffect(() => {
    if (!process.browser) return;
    document.addEventListener(event, action, bubble);
    return () => document.removeEventListener(event, action, bubble);
  }, props);
};
export const useWindowEventListener = (event = "", action, props = []) => {
  useEffect(() => {
    if (!process.browser) return;
    window.addEventListener(event, action);
    return () => window.removeEventListener(event, action);
  }, props);
};

export const useKeyUp = (keyCode, action, props) => {
  useEventListener(
    "keyup",
    (e) => e.keyCode === keyCode && action(e, props),
    props
  );
};

export const useKeyDown = (keyCode, action, props) => {
  useEventListener(
    "keydown",
    (e) => e.keyCode === keyCode && action(e, props),
    props
  );
};

export const useEscapeKey = (action, props = []) => {
  useKeyUp(27, action, props);
};

export const useMouseDown = (props = []) => {
  const [mouseDown, setMouseDown] = useState(false);
  let timeout;

  const handleAction = () => setMouseDown(true);

  const handleMouseDown = () => {
    timeout = setTimeout(handleAction, 250);
  };
  const handleMouseUp = () => {
    clearTimeout(timeout);
    setMouseDown(false);
  };

  useEventListener("mousedown", handleMouseDown, [
    ...props,
    mouseDown,
    setMouseDown,
  ]);
  useEventListener("mouseup", handleMouseUp, [
    ...props,
    mouseDown,
    setMouseDown,
  ]);

  return mouseDown;
};

export const useHeldKey = (keyCode, props = []) => {
  const [isHeldDown, setIsHeldDown] = useState(false);
  let timeout;

  const handleAction = () => {
    // if (document?.activeElement?.nodeName === "INPUT") return;
    setIsHeldDown(true);
  };

  const handleKeyDown = () => handleAction();
  const handleKeyUp = () => {
    clearTimeout(timeout);
    setIsHeldDown(false);
  };

  useKeyDown(keyCode, handleKeyDown, [
    ...props,
    timeout,
    isHeldDown,
    setIsHeldDown,
  ]);
  useKeyUp(keyCode, handleKeyUp, [
    ...props,
    timeout,
    isHeldDown,
    setIsHeldDown,
  ]);

  useEffect(() => {
    window.addEventListener("blur", handleKeyUp);
    return () => window.removeEventListener("blur", handleKeyUp);
  }, [...props, isHeldDown, setIsHeldDown, timeout]);

  useEffect(() => {
    return () => timeout && clearTimeout(timeout);
  }, []);

  return isHeldDown;
};

export function useWindowResize(action, props) {
  useWindowEventListener("resize", action, props);
}
