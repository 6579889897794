"use client";

import { Check, ChevronsUpDown } from "lucide-react";
import * as React from "react";
import { Button } from "ui/components/primitives/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuTrigger } from "ui/components/primitives/dropdown-menu";
import { useConfig } from "ui/hooks/use-config";
import { cn } from "ui/lib/utils";
import { themes } from "ui/themes";
import { useThemeMode } from "./theme-mode-provider";
export function ThemeCombobox({
  resetVariables
}) {
  const [config, setConfig] = useConfig();
  const activeTheme = themes.find(theme => theme.name === config.theme);
  const {
    theme: mode = "dark"
  } = useThemeMode();
  const [open, setOpen] = React.useState(false);
  return <DropdownMenu open={open} onOpenChange={setOpen} data-sentry-element="DropdownMenu" data-sentry-component="ThemeCombobox" data-sentry-source-file="theme-combobox.tsx">
      <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="theme-combobox.tsx">
        <Button variant="outline" role="combobox" aria-expanded={open} className="w-[200px] justify-between" data-sentry-element="Button" data-sentry-source-file="theme-combobox.tsx">
          {activeTheme ? activeTheme.name : "Select theme..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" data-sentry-element="ChevronsUpDown" data-sentry-source-file="theme-combobox.tsx" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[200px] p-0 z-50" data-sentry-element="DropdownMenuContent" data-sentry-source-file="theme-combobox.tsx">
        <DropdownMenuGroup data-sentry-element="DropdownMenuGroup" data-sentry-source-file="theme-combobox.tsx">
          {themes.map((theme, index) => <DropdownMenuItem key={index} className={`cursor-pointer gap-2`} onSelect={event => {
          setConfig({
            ...config,
            theme: theme.name
          });
          setOpen(false);
          resetVariables();
        }} style={theme && {
          "--theme-primary": `hsl(${theme.cssVars[mode === "dark" ? "dark" : "light"].primary})`,
          "--theme-primary-foreground": `hsl(${theme.cssVars[mode === "dark" ? "dark" : "light"]["primary-foreground"]})`
        } as React.CSSProperties}>
              <span className={cn("flex h-6 w-6 items-center justify-center rounded-full bg-[var(--theme-primary)] text-[var(--theme-primary-foreground)]")}>
                {activeTheme && activeTheme.name === theme.name && <Check className="h-4 w-4" />}
              </span>
              <span className="sr-only">{theme.label}</span>
              {theme.label}
            </DropdownMenuItem>)}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>;
}