import { useQuery, UseQueryOptions } from "react-query";
import { getAccount } from "./services";
import { Account } from "./types";

export const useAccount = (
  userID: string,
  queryProps?: UseQueryOptions<Account, unknown, Account, string[]>
) => {
  const queryKey = userID ? ["getUserAccount", userID] : undefined;

  const { data, error, isLoading, refetch } = useQuery(
    queryKey!,
    () => getAccount(userID),
    {
      refetchOnWindowFocus: false,
      enabled: !!queryKey, // enable the query only if queryKey is not undefined
      ...queryProps,
    }
  );

  return {
    data,
    error,
    refetch,
    isLoading,
  };
};
