import localFont from "next/font/local";

export const SFProDisplayRegular = localFont({
  src: "../public/fonts/SFPro-Display-Regular.woff2",
  display: "swap",
  variable: "--sfpro-display-regular",
});
export const SFProDisplayMedium = localFont({
  src: "../public/fonts/SFPro-Display-Medium.woff2",
  display: "swap",
  variable: "--sfpro-display-medium",
});
export const SFProDisplayBold = localFont({
  src: "../public/fonts/SFPro-Display-Bold.woff2",
  display: "swap",
  variable: "--sfpro-display-bold",
});
export const SFProDisplayHeavy = localFont({
  src: "../public/fonts/SFPro-Display-Heavy.woff2",
  display: "swap",
  variable: "--sfpro-display-heavy",
});
export const DrukBold = localFont({
  src: "../public/fonts/Druk-Bold.otf",
  display: "swap",
  variable: "--druk-bold",
});
export const DrukWideBold = localFont({
  src: "../public/fonts/druk-wide-bold.ttf",
  display: "swap",
  variable: "--druk-wide-bold",
});

export const fonts = [
  SFProDisplayRegular,
  SFProDisplayMedium,
  SFProDisplayBold,
  SFProDisplayHeavy,
  DrukBold,
  DrukWideBold,
];
