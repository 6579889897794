import { rockyFetcher } from "ui/lib/rockyFetcher";
import useRequest from "ui/lib/useRequest";

export const useUserEntitlements = (userID, props = {}) => {
  return useRequest(userID && `/getDetailedPaymentState?userID=${userID}`, {
    _fetcher: rockyFetcher,
    revalidateOnMount: true,
    dedupingInterval: 10000000,
    ...props,
  });
};
