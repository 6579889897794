import { Team, getTeam, getTeamsData } from "queries/teams";
import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { TeamCoach, TeamsResponse, User } from "./types";

export function getTeamCoaches(
  teamID: string,
  teamCoaches: TeamCoach[],
  users: User[]
) {
  // filter the coaches for the given teamID
  const coachesForTeam = teamCoaches.filter((coach) => coach.teamID === teamID);

  // map the filtered coaches to their user details
  return coachesForTeam.map((coach) =>
    users.find((user) => user.id === coach.coachUserID)
  );
}

export const useTeams = (teamID?: string | null) => {
  const { data, error, isLoading } = useQuery<TeamsResponse, Error>(
    "/teams-data",
    getTeamsData,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { teams, teamCoaches, practiceWorkouts, users } = data || {};

  const [activeTeam] = useMemo(
    () =>
      teamID && teams ? teams.filter(({ id }) => teamID === id) : teams || [],
    [teamID, teams]
  );

  const getTeamById = useCallback(
    (ID: string | null): Team | undefined => {
      if (ID) {
        return teams?.find(({ id }) => ID === id);
      }
    },
    [teams]
  );

  return {
    teams,
    teamCoaches,
    practiceWorkouts,
    users,
    teamID,
    activeTeam,
    getTeamById,
    error,
    isLoading,
  };
};

export const useTeam = (teamID: string, props = {}) => {
  const { data, error, isLoading } = useQuery(
    teamID && `/v1/team/${teamID}`,
    getTeam(teamID),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: 10000000,
      enabled: !!teamID,
      ...props,
    }
  );

  return {
    data,
    teamID,
    error,
    isLoading,
  };
};
