import { useCallback, useRef } from "react";

export function useBodyCSSVariable() {
  const variablesRef = useRef(new Set<string>());

  const setVariable = useCallback((name, value) => {
    document.body.style.setProperty(name, value);
    variablesRef.current.add(name); // Keep track of the variable name
  }, []);

  const resetVariables = useCallback(() => {
    // Iterate through the variables set by this hook and remove them
    for (const name of variablesRef.current) {
      document.body.style.removeProperty(name);
    }
    variablesRef.current.clear(); // Clear the set of variable names
  }, []);

  return { setVariable, resetVariables };
}
