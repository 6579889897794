"use client";

import React from "react";
import { useConfig } from "ui/hooks/use-config";
import { cn } from "ui/lib/utils";
interface ThemeWrapperProps extends React.ComponentProps<"div"> {
  defaultTheme?: string;
}
export function ThemeWrapper({
  defaultTheme,
  children,
  className
}: ThemeWrapperProps) {
  const [config] = useConfig();
  return <div className={cn(`theme-${defaultTheme || config.theme}`, "w-full", className)} style={{
    "--radius": `${defaultTheme ? 0.5 : config.radius}rem`
  } as React.CSSProperties} data-sentry-component="ThemeWrapper" data-sentry-source-file="theme-wrapper.tsx">
      {children}
    </div>;
}